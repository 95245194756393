<template>
  <div>
    <Card :bordered="false" dis-hover class="ivu-mt">
      <Form
        :model="searchWhere"
        :label-width="80"
        label-position="right"
        @submit.native.prevent>
        <Row type="flex" :gutter="24">
          <Col v-bind="grid">
            <FormItem label="是否显示：">
              <Select v-model="searchWhere.status" placeholder="请选择" clearable @on-change="handleSearchs">
                <Option value="1">显示</Option>
                <Option value="0">不显示</Option>
              </Select>
            </FormItem>
          </Col>
          <Col v-bind="grid">
            <FormItem label="分类名称：" label-for="status2">
              <Input v-model="searchWhere.title" search enter-button placeholder="请输入分类名称" @on-search="handleSearchs"/>
            </FormItem>
          </Col>
        </Row>
        <Row type="flex">
          <Col v-bind="grid">
            <Button type="primary" icon="md-add" @click="handleCreateClassify" class="mr20">添加配置分类</Button>
          </Col>
        </Row>
      </Form>

      <vxe-table
        :border="false"
        class="vxeTable l-m-t25"
        highlight-hover-row
        highlight-current-row
        :loading="loading"
        ref="xTable"
        :tree-config="tabconfig"
        :data="classifyList"
        row-id="id">
        <vxe-table-column field="id" title="ID" tooltip width="60"></vxe-table-column>
        <vxe-table-column field="title" tree-node title="分类名称" min-width="150"></vxe-table-column>
        <vxe-table-column field="eng_title" title="分类字段" min-width="150"></vxe-table-column>
        <vxe-table-column field="statuss" title="状态" min-width="150">
          <template v-slot="{ row }">
            <i-switch
              v-model="row.status"
              :value="row.status"
              :true-value="1"
              :false-value="0"
              @on-change="handleChangeStatus(row)"
              size="large">
              <span slot="open">显示</span>
              <span slot="close">隐藏</span>
            </i-switch>
          </template>
        </vxe-table-column>
        <vxe-table-column field="action" title="操作" min-width="150" fixed="right">
          <template v-slot="{ row, index }">
            <a @click="handleGoList(row)">配置列表</a>
            <Divider type="vertical" />
            <a @click="handleEdit(row)">编辑</a>
            <Divider type="vertical" />
            <a class="l-color-error" @click="handleDelete(row, '删除分类', index)">删除</a>
          </template>
        </vxe-table-column>
      </vxe-table>
      <div class="acea-row row-right l-m-t25">
        <Page :total="total" :current="searchWhere.page" show-elevator show-total @on-change="handlePageChange" :page-size="searchWhere.limit"/>
      </div>
    </Card>

     <!--新建  编辑表单-->
    <CustomForm ref="edits" :update="true" :FromData="FromData" @submitFail="handleSubmitFail"></CustomForm>
  </div>
</template>

<script>
import mixins from '@/mixins'
import {
  getClassifyListApi,
  createClassConfigApi,
  editClassifyApi
} from '@/api/setting'

export default {
  name: 'index',
  mixins: [mixins],
  data () {
    return {
      // 搜索条件
      searchWhere: {
        title: '',
        status: '',
        page: 1,
        limit: 15
      },
      // 表格配置
      tabconfig: { children: 'children', reserve: true, accordion: true },
      classifyList: [],

      FromData: null
    }
  },
  mounted () {
    this.getClassifyListRequest()
  },
  methods: {
    // 获取配置分类列表
    getClassifyListRequest () {
      this.loading = true
      getClassifyListApi(this.searchWhere).then(res => {
        this.loading = false
        this.classifyList = res.data.list
        this.total = res.data.count
      }).catch(err => {
        this.loading = false
        this.$Message.error(err.msg)
      })
    },
    // 处理进入下一项数据内部
    handleGoList (row) {
      this.$router.push({
        path: this.$config.routePre + '/system/config/system_config_tab/list/' + row.id
      })
    },
    // 处理编辑
    handleEdit (row) {
      editClassifyApi(row.id).then(async (res) => {
        if (res.data.code === false) {
          return this.$authLapse(res.data)
        }
        this.FromData = res.data
        this.$refs.edits.modals = true
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    },
    // 处理删除
    handleDelete (row, tit, num) {
      let delfromData = {
        title: tit,
        num: num,
        url: `system/config_class/${row.id}`,
        method: 'DELETE',
        ids: ''
      }
      this.$overallModal(delfromData).then((res) => {
        this.$Message.success(res.msg)
        // this.classList.splice(num, 1);
        this.getList()
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    },
    // 处理状态
    handleChangeStatus () {},
    // 处理搜索
    handleSearchs () {},
    // 创建
    handleCreateClassify () {
      createClassConfigApi().then(async (res) => {
        this.FromData = res.data
        this.$refs.edits.modals = true
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    },
    // 处理分页
    handlePageChange () {},
    handleSubmitFail () {
      this.getClassifyListRequest()
    }
  }
}
</script>

<style scoped>

</style>
